.lg-react-element {
    column-count: 4;
    column-gap: 20px;
}

img-galery {
    max-width: 100%;
    display: block;
    padding: 5px 0px;
    border-radius: 20px;
    transition: transform 0.2s;
}

img-galery > img:hover {
    filter: opacity(.9);
    transform: scale(1.01);
}
