.cespedFooter {
    width: 100vw;
    margin-bottom: 0;
}

.myTitle {
    max-width: 700px;
    margin: 20px auto 75px;
}

.marginTop {
    margin-top: 30px;
}

.moreWeight {
    font-weight: 600;
}

.col-presupuesto {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.containerInput {
    display: flex;
    justify-content: center;
    align-items: center;
}

.altura {
    min-height: 15vh;
}

.padding-img img {
    padding: 20px;
}

.margin-left {
    margin-left: 10px;
}

.withFormDiv {
    width: 100%;
}
.heigthFormDiv {
    height: 30vh !important;
    max-height: 1000px !important;
}

.enlace {
    cursor: pointer;
    text-decoration: none !important;
}

.titlePresupuesto {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h2 {
        margin-bottom: 0;
    }
    h4 {
        margin-top: 0 !important;
        padding-top: 20px;
        margin-bottom: 1.5em;
    }
}

.link-presupuesto {
    color: black;
}
.btn-nav-min-w{
    min-width: 10vw;
}
.my-nav{
    min-width: 90vw;
}

.form-control:focus {
    min-width: 0;
    border-color: green;
}

.marginTextPhone {
    margin-left: 15px;
    color: black;
}

.spinnerMe {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.marginBottom {
    margin-bottom: -10px;
}

.iframe {
    float: none;
    clear: both;
    width: 900px;
    position: relative;
    padding-bottom: 30%;
    padding-top: 25px;
    height: 0;
    margin: 0 auto;
}

.ifram {
    height: 60vh;
}
.imgService {
    width: 187px !important;
    padding: 0px !important;
}

.titlePadding {
    padding-top: 0 !important;
}
.marginBottom {
    margin-bottom: 60px;
}

.marginTitleYoutube {
    margin-bottom: -15px;
}

.sivicolasWrapper {
    margin-top: 90px;
}

.paragraphElectric {
    margin-top: 60px;
}

.marginTitleH2 {
    margin-bottom: 0;
}

.marginCarrousel {
    margin-top: 40px;
}

.mb {
    margin-bottom: 50px;
}
.mt {
    margin-top: 85px;
}
.mail {
    margin-right: 5px;
}
.containerMail {
    margin-right: 0px;
    color: black;
    display: flex;
    justify-content: flex-end;
}
.marginTextMail{
    margin-right: 10px;
}
.hr{
    margin:2px
}
